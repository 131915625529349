@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;lang=en');

body {
    height: 100%;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 16px;
    background-color: #fff;
}

hr {
    height: 3px;
}

.separator-double {
    border-top: 1px solid #0078f0;
    border-bottom: 1px solid #0078f0;
    max-width: 300px;
}

.img-center {
    margin: 0 auto;
}

.img-logo-small {
    width: 75px;
    height: 73px;
}

.img-logo-med {
    width: 120px;
    height: 120px;
}

.scroll-me {
    position: relative;
    z-index: 5;
}

.btn-scroll-down,
.btn-scroll-down:active {
    position: absolute;
    top: -32px;
    left: 48.3%;
    height: 60px;
    width: 60px;
    background: url(assets/images/btn-scroll-arrow.png) no-repeat;
    box-shadow: none;
}

.btn-scroll-down:hover {
    height: 60px;
    width: 60px;
    background: url(assets/images/btn-scroll-arrow-hover.png) no-repeat;
    box-shadow: none;
}

.btn-highlight {
    float: right;
    color: #fff;
    background-color: #00376f;
    border: 1px solid #00376f;
}

.btn-highlight:hover,
.btn-highlight:active,
.btn-highlight:focus {
    background-color: #0078f0;
    color: #fff;
    border: 1px solid #0078f0;
}

.btn-highlight[disabled]:hover {
    background-color: #00376f;
    border: 1px solid #00376f;
}

a {
    color: #0078f0;
}

a:hover {
    color: #00376f;
}

p {
    font-size: 18px;
}

.text-highlight {
    color: #0078f0;
}

.text-bold {
    font-weight: 700;
}

.text-small {
    font-size: 0.8em;
}

.navbar-inverse {
    background-color: #00376f;
    height: 50px;
    border: none;
}

.navbar-header .navbar-brand {
    display: none;
}

.navbar-nav .nav-item .nav-link {
    color: white;
}

.navbar-center {
    float: none;
    padding-left: 50px;
    position: relative;
    top: -20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.navbar-center .navbar-brand {
    top: -40px;
    height: 50px;
    padding: 15px 0 15px 15px;
}

.navbar-center .navbar-brand a {
    padding-left: 5px;
}

.navbar-center li {
    display: inline-block;
    float: none;
}

.navbar-center li:hover:before {
    height: 5px;
    width: 100%;
    display: block;
    content: ' ';
    top: 6px;
    left: 0;
    position: inherit;
    background: #0078f0;
}

.navbar-center .navbar-brand:hover:before {
    height: 0;
    background: none;
    border-top: none;
}

.navbar-inverse .navbar-center li a {
    color: #fff;
}

header {
    position: relative;
    display: block;
    width: auto;
    height: 480px;
    background-color: #00376f;
    padding: 40px 0 0 0;
}

.header-content {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.header-content img {
    max-width: 400px;
    max-height: 387px;
    z-index: -1;
}

aside {
    position: relative;
    z-index: 2;
    background: #00376f;
    height: 100px;
    overflow: hidden;
}

section {
    background: #fff;
    padding: 40px 0 100px 0;
    position: relative;
    z-index: 4;
}

section#about p {
    padding: 0 30px;
}

section#youtube {
    padding: 40px 0 60px 0;
}

.bg-water {
    background: url(assets/images/bg-water.jpg) no-repeat center center;
    background-attachment: scroll;
    background-size: cover;
    position: relative;
    z-index: -2;
}

.bg-water-fixed {
    background: url(assets/images/bg-water.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
}

ul.skills {
    position: relative;
    list-style-type: none;
}

.skills li {
    position: relative;
    float: left;
    color: #fff;
    font-size: 15px;
    text-align: center;
    width: 17%;
    margin: 1%;
    cursor: pointer;
}

.skills li a {
    color: #fff;
}

.skill-image,
.skill-info {
    float: left;
    overflow: hidden;
}

.skill-info {
    background-color: #00376f;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    padding: 29% 0 29% 0;
}

ul.youtube {
    list-style-type: none;
    line-height: 1.8em;
}

#explore ul {
    display: inline-block;
}

#explore ul li {
    text-align: left;
}

footer {
    position: relative;
    z-index: 4;
    background: #00376f;
    height: 200px;
}

footer .container {
    color: #fff;
    font-weight: bold;
    position: relative;
    top: 30px;
}

footer p {
    padding-top: 25px;
}

@media screen and (max-width: 1200px) {
    section#about p {
        padding: 0;
    }

    .skills li {
        font-size: 0.65em;
    }

    .skill-image img {
        width: 127px;
    }
}

@media screen and (max-width: 1000px) {

    h1,
    h2 {
        font-size: 1.9em;
    }

    p,
    label,
    input {
        font-size: 0.95em;
    }

    .navbar-center li a {
        font-size: 0.7em;
    }

    aside {
        height: 80px;
    }

    .skills li {
        font-size: 0.5em;
    }

    .skill-image img {
        width: 95px;
    }
}

@media screen and (max-width: 768px) {

    h1,
    h2 {
        font-size: 1.8em;
    }

    p,
    label,
    input {
        font-size: 0.9em;
    }

    .navbar-toggle {
        border: none !important;
    }

    .navbar-toggle:active,
    .navbar-toggle:hover,
    .navbar-toggle:focus {
        background-color: #0078f0 !important;
    }

    .navbar-inverse .navbar-collapse {
        border-color: #002142;
    }

    .navbar-collapse {
        position: absolute;
        bottom: 50px;
        width: 100%;
        background-color: #00376f;
        border-bottom: 1px solid transparent;
        box-shadow: inset 0 0 1px rgba(0, 33, 66, 0.1);
    }

    .navbar-header .navbar-brand {
        display: inherit;
        position: absolute;
        top: -10px;
        left: -10px;
    }

    .navbar-center {
        padding-left: 0;
        top: 0;
    }

    .navbar-center .navbar-brand {
        display: none;
    }

    .navbar-center li {
        display: block;
    }

    .navbar-center li a {
        font-size: 1em;
    }

    .navbar-center li a:hover {
        color: #0078f0 !important;
    }

    .navbar-center li:hover:before {
        display: none;
    }

    .img-logo-small {
        width: 40px;
        height: 39px;
    }

    .img-logo-med {
        width: 70px;
        height: 70px;
    }

    .btn-scroll-down {
        display: none;
    }

    section {
        padding: 30px 0 60px 0;
    }

    section#skills .col-xs-offset-1 {
        margin-left: 5%;
    }

    .skills li {
        width: 23%;
    }

    .skill-image img {
        width: 88px;
    }

    .skill-info {
        height: 100%;
        padding: 18% 0 18% 0;
    }

    footer .container {
        padding: 0;
    }
}

@media screen and (max-width: 520px) {

    h1,
    h2 {
        font-size: 1.4em;
    }

    p,
    label,
    input {
        font-size: 0.9em;
    }

    .header-content img {
        max-width: 80%;
    }

    #secondary-skills p {
        padding: 10px 30px;
    }

    section {
        padding: 10px 0 50px 0;
    }

    section#skills .col-xs-offset-1 {
        margin-left: 4%;
    }

    aside {
        height: 60px;
    }

    .skills li {
        font-size: 0.4em;
    }

    .skill-image img {
        width: 69px;
    }

    footer {
        height: 150px;
    }

    footer p {
        padding-top: 5px;
        font-size: 1em;
    }
}

@media screen and (max-width: 460px) {
    header {
        height: 450px;
    }

    section#skills .col-xs-offset-1 {
        margin-left: 6.5%;
    }

    .skills li {
        width: 28%;
        font-size: 0.3em;
    }

    .skill-image img {
        width: 54px;
    }
}